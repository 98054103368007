import React, { Component } from 'react';

import './service2.css'
import img1 from '../../../components/assets/solar-panels.jpg';
import img2 from '../../../components/assets/ind-solar.jpg';
import img3 from '../../../components/assets/solar-installers.webp';
import img4 from '../../../components/assets/solar-consulting.jpg';


import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

const Service2page = () => {
    return ( 
        <div className='service2-main'>
         <div className='service2-container'>
         <div className="section-with-cross-overlay-heb">
    <div className='service2-text'>
      <h2>שרותי אנרגייה סולארית</h2>
      <p>אנו מתמחים במתן פתרונות אנרגיה סולארית איכותיים הן למגורים והן לנכסים מסחריים. צוות המומחים שלנו יכול לתכנן ולהתקין מערכות סולאריות מותאמות אישית כדי לענות על צרכי האנרגיה והתקציב שלך. אנו משתמשים רק בציוד ובחומרים הטובים ביותר, כולל יבוא מספקים מהימנים, כדי להבטיח את הביצועים והאמינות הטובים ביותר. עם אנרגיה סולארית, אתה לא רק יכול להפחית את חשבונות האנרגיה שלך אלא גם להרוויח מייצור אנרגיה עודף באמצעות מדידת נטו. תנו לנו להדריך אתכם בתהליך ולעזור לכם לנצל את היתרונות הרבים של אנרגיה סולארית.</p>
    
      </div>
      </div>
    <div className='service2-services-icons'>
                <Card sx={{ maxWidth: 345,  textAlign: 'right' }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="250"
                        image={img1}
                        loading='lazy'
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" >
                        שירותי סולאריים למגורים
                        </Typography>
                        <Typography variant="body2" color="black">
                        אנו מציעים פתרונות סולאריים מלאים לביתכם, לרבות שירותי התקנה, תחזוקה ותיקונים. צוות המומחים שלנו יעבוד איתך כדי לתכנן מערכת סולארית מותאמת אישית המתאימה לצרכי האנרגיה ולתקציב שלך.
                        </Typography>
                    </CardContent>
                </Card>

                   <Card sx={{ maxWidth: 345,  textAlign: 'right'}}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="250"
                        image={img2}
                        loading='lazy'
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        שירותים סולאריים מסחריים
                        </Typography>
                        <Typography variant="body2" color="black">
                        אנו מספקים שירותי אנרגיה סולארית מקיפים לעסקים בכל הגדלים. לצוות שלנו ניסיון רב בהתקנה ותחזוקה של מערכות סולאריות לנכסים מסחריים, ואנחנו יכולים לעזור לך להפחית את עלויות האנרגיה שלך ולהגדיל את הקיימות שלך.                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ maxWidth: 345 ,  textAlign: 'right'}}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="250"
                        image={img3}
                        loading='lazy'
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        התקנת פאנלים סולאריים
                        </Typography>
                        <Typography variant="body2" color="black">
                        אנו מתמחים בהתקנת פאנלים סולאריים לבתים ועסקים. הצוות שלנו יעבוד איתך כדי לתכנן מערכת שתענה על צרכי האנרגיה שלך ותמקסם את החיסכון באנרגיה שלך. אנו משתמשים בפאנלים וציוד סולארי באיכות גבוהה כדי להבטיח את הביצועים והאמינות הטובים ביותר.                        </Typography>
                    </CardContent>
                </Card>
                    
                <Card sx={{ maxWidth: 345 ,  textAlign: 'right'}}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="250"
                        image={img4}
                        loading='lazy'
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        ייעוץ אנרגיה סולארית                        </Typography>
                        <Typography variant="body2" color="black">
                        צוות המומחים שלנו לאנרגיה סולארית יכול לספק לך שירותי ייעוץ מקיפים כדי לעזור לך להבין את היתרונות של אנרגיה סולארית ולקבל החלטות מושכלות לגבי צרכי האנרגיה שלך. מהערכת אתר ועד לניתוח פיננסי, אנחנו יכולים להדריך אותך בכל שלב בתהליך.
                        </Typography>
                    </CardContent>
                </Card>
                </div>
            </div>
        </div>
     );
}
 
export default Service2page;
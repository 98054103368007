import React, { Component, useEffect } from "react";
import './App.css';
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import ApplicationpageHEB from "./components/heb/app/Application";
import Applicationpage from "./components/en/app/Application";
import CalgulatorPage from "./components/en/Calculator/Calculator";
import CalgulatorPageheb from "./components/heb/Calculator/Calculator";
import PrivacyPolicy from "./components/privacy/pp";
import TosPage from "./components/tos/Tos";
function App() {
 

  return (
    <div className="App">
      <BrowserRouter>

      <Routes>
      <Route path='/' element={<ApplicationpageHEB/>}/>
      <Route path='/en' element={<Applicationpage/>}/>
      <Route path='/en/calculate' element={<CalgulatorPage/>}/>

      <Route path='/calculate' element={<CalgulatorPageheb/>}/>
      <Route path='/tos' element={<TosPage/>}/>
      <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>

      </Routes>
      </BrowserRouter>
     
    
    </div>
  );
}

export default App;

import React, { Component } from 'react';
import solarinstaller from '../../../components/assets/download2.jpg'
import clockhours from '../../../components/assets/clock.png'
import locationicon from '../../../components/assets/location.png'
import customercall from '../../../components/assets/customer-service.png'
import suplol from '../../../components/assets/whatsapp.png'


import './foot.css'
const Footerpage = () => {
    return (
      <div className="footer-container">
        <div className="rectangle">
          <div className="image-section">
            <img src={solarinstaller} alt="solar installer" />
          </div>
          <div className="text-section-heb">
            <h2>אייקארגו</h2>
            <p>ב-אייקארגו, צוות המומחים שלנו מחויב לספק פתרונות איכותיים לכל הצרכים שלך. עם שנים של ניסיון והכשרה מתמשכת, אנו מחויבים להישאר בקדמת העקומה ולמצוא דרכים חדשניות שיעזרו לך להשיג את המטרות שלך. סמוך עלינו על תמיכה 24/7 ותוצאות ללא תחרות.
</p>
          </div>
        </div>
        
        <div className='office-info-grid'>
  <div className='office-info-grid-item'>
    <div className='office-info-grid-item-content'>
      <img src={clockhours}/>
      <div className='office-info-grid-item-text'>
        <h4>שעות פעילות</h4>
        <p>שני - חמישי </p>
        <p> 8.00 - 18.00</p>
      
      </div>
    </div>
  </div>
  <div className='office-info-grid-item'>
    <div className='office-info-grid-item-content'>
      <img src={locationicon}/>
      <div className='office-info-grid-item-text'>
        <h4>מיקום</h4>
        <p>חיפה</p>
        <p>העצמאות 102</p>
      </div>
    </div>
  </div>
  <div className='office-info-grid-item'>
    <div className='office-info-grid-item-content'>
      <img src={customercall}/>
      <div className='office-info-grid-item-text'>
        <h4>מוקד</h4>
        <p>054-7777391</p>
        
      </div>
    </div>
  </div>
  <div className='office-info-grid-item'>
    <div className='office-info-grid-item-content'>
      <img src={suplol}/>
      <div className='office-info-grid-item-text'>
        <h4>צרו קשר</h4>
        
        <p>anan@icargo.ltd</p>
      </div>
    </div>
  </div>
</div>
</div>
    );
  }
export default Footerpage;
import React, { useState, useEffect } from 'react';
import './main.css';
import TextField from '@mui/material/TextField';
import gifFile from '../../../components/assets/ezgif.com-video-to-gif.gif';

import logo from '../../../components/assets/logogo.png';
import videoFile from '../../../components/assets/film_homepage_3256dsfds.mp4';

const Mainpage = () => {
 

  return (
    <div className="Mainpage-en">
      <div className='gif-wrapper-en'>
        <img
          className="home-main-video-en"
          src={gifFile}
          alt="solar panel"
          
          
          muted
          loop
        
        />
          <div className="lmao-div">
            
            <p className="lmao">INNOVATIVE SOLAR SOLUTIONS</p>
            <button className="main-btn">FIND OUT MORE</button>
          </div>

          <div className="contact-main">
            <p className="contact-title">
              Leave your contact information below and we will be in touch!
            </p>

            <form>
              <TextField
                type="text"
                id="name"
                name="name"
                label="Name"
                variant="filled"
                required
                sx={{ bgcolor: 'white' }}
              />

              <TextField
                type="email"
                id="email"
                name="email"
                label="Email"
                variant="filled"
                required
                sx={{ bgcolor: 'white' }}
              />

              <TextField
                id="phone"
                name="phone"
                label="Phone"
                required
                variant="filled"
                sx={{ bgcolor: 'white' }}
              />

              <button className="contact-submit" type="submit">
                Get started
              </button>
            </form>
          </div>
        </div>
     
        
        
  
    </div>
  );
};

export default Mainpage;
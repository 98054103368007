import React from 'react';
import image from '../../../components/assets/solar-work.webp';
import solaricon from '../../../components/assets/solar-energy.png';
import solarprofit from '../../../components/assets/solar-profit.png';
import greenhouse from '../../../components/assets/green-home.png';
import './Bout.css';

const AboutPage = () => {
  return (
    <div className='bout-div-heb'>
        
      <p className='solarity-us-heb'>ב-אייקארגו, צוות המומחים שלנו מחויב לספק פתרונות איכותיים לכל הצרכים שלך. עם שנים של ניסיון והכשרה מתמשכת, אנו מחויבים להישאר בקדמת העקומה ולמצוא דרכים חדשניות שיעזרו לך להשיג את המטרות שלך. סמוך עלינו על תמיכה 24/7 ותוצאות ללא תחרות</p>

      <div className='bout-container-heb'>

        <div className='bout-solar-div-heb'>
          
        <div className="bout-flex-container-heb">
  <img className="bout-solar-icon-heb" src={solaricon} alt="solar icon" loading='lazy'/>
  <p id="about-solar-title-heb">אנרגייה סולארית</p>
</div>
<p id="about-solar-text-heb">
  אנרגיה סולארית היא מקור חשמל נקי ומתחדש שיש לו פוטנציאל לחולל מהפכה בדרך שבה אנו מייצרים חשמל. בניגוד לדלקים מאובנים מסורתיים, אנרגיה סולארית קיימת בשפע וזמינה, מה שהופך אותה לחלופה בת קיימא שיכולה להפחית את טביעת הרגל הפחמנית שלנו ולהילחם בשינויי האקלים.
</p>
</div>

        <div className="profit-from-solar">
  <div className="bout-flex-container-heb">
    <img className="bout-solar-profit-heb" src={solarprofit} alt="solar profit" loading='lazy'/>
    <p id="profit-solar-title-heb">רווחים מאנרגייה סולרית</p>
  </div>
  <p id="profit-solar-text-heb">
    השקעה באנרגיה סולארית לא רק טובה לכדור הארץ, היא גם טובה לארנק שלך. על ידי הפקת חשמל משלך, אתה יכול להפחית את התלותך בחברות חשמל יקרות ולהשתלט על עלויות האנרגיה שלך. ב-אייקארגו, אנו מציעים מגוון אפשרויות מימון שיעזרו לכם לבצע את המעבר לסולארי מבלי לשבור את הכסף.
  </p>
</div>

        <div className="future-of-solar">
  <div className="bout-flex-container-heb">
    <img className="future-profit-icon-heb" src={greenhouse} alt="solar profit" loading='lazy'/>
    <p id="future-of-title-heb">העתיד מבוסס על אנרגיה סולארית</p>
  </div>
  <p id="future-of-text-heb">
    ההתקדמות בטכנולוגיה הסולארית הפכה את זה קל מתמיד לרתום את כוחה של השמש לשימוש יומיומי. מפאנלים סולאריים על הגג ועד לגינות סולאריות קהילתיות, יש מגוון אפשרויות זמינות שיעזרו לך לעבור לאנרגיה נקייה ומתחדשת.
  </p>
</div>


    </div>
      <div className='bout-image-border-div'>
        <img className='bout-worker-image' src={image} alt='solar worker' loading='lazy'/>
      </div>
    </div>
  );
};

export default AboutPage;
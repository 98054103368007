import React from 'react';
import './pp.css'; // Import the CSS file for styling

const PrivacyPolicy = () => {
    return (
      <div className="privacy-container">
        <h1 className="privacy-title">Privacy Policy</h1>
        <p className="privacy-text">
          Effective Date: [Insert Effective Date]
        </p>
  
        <h2>1. Information Collection and Use</h2>
        <p>
          a. We collect personal information from individuals who engage with our solar energy installation and marketing services.
          <br />
          b. The collected information may include name, contact details, address, energy usage data, and other relevant information necessary for providing our services.
          <br />
          c. We use this information to deliver our services, communicate with individuals, and improve our offerings.
        </p>
  
        <h2>2. Data Security</h2>
        <p>
          a. We take reasonable measures to protect the personal information collected from unauthorized access, disclosure, or alteration.
          <br />
          b. We follow industry-standard practices to ensure data security; however, no method of transmission or electronic storage is completely secure.
          <br />
          c. We cannot guarantee the absolute security of personal information.
        </p>
  
        <h2>3. Information Sharing and Disclosure</h2>
        <p>
          a. We may share personal information with trusted third-party service providers who assist us in delivering our services.
          <br />
          b. We may disclose personal information to comply with legal obligations, enforce our policies, or protect our rights and the rights of others.
          <br />
          c. We do not sell or rent personal information to third parties for marketing purposes.
        </p>
  
        <h2>4. Cookies and Tracking Technologies</h2>
        <p>
          a. We use cookies and similar tracking technologies to enhance the user experience and collect information about website usage.
          <br />
          b. Individuals can adjust their browser settings to refuse cookies or receive alerts when cookies are being sent.
          <br />
          c. However, disabling cookies may impact certain features and functionality of the website.
        </p>
  
        <h2>5. Third-Party Links</h2>
        <p>
          a. Our website may contain links to third-party websites or services that are not owned or controlled by us.
          <br />
          b. We are not responsible for the privacy practices or content of such third-party websites.
          <br />
          c. We encourage individuals to read the privacy policies of those websites before providing any personal information.
        </p>
  
        <h2>6. Changes to This Privacy Policy</h2>
        <p>
          a. We reserve the right to update or modify this Privacy Policy at any time.
          <br />
          b. Any changes to the Privacy Policy will be effective upon posting the revised version on our website.
          <br />
          c. It is the responsibility of individuals to review this Privacy Policy periodically for any updates or changes.
        </p>
  
        <h2>7. Contact Us</h2>
        <p>
          If you have any questions or concerns regarding this Privacy Policy, please contact us at [insert contact information].
        </p>
      </div>
    );
  };
  
  export default PrivacyPolicy;
import React from 'react';
import solarwork from '../../../components/assets/inner_solar.png';
import invertericon from '../../../components/assets/converter.png';
import solarhouseicon from '../../../components/assets/eco-house123.png';
import energynet from '../../../components/assets/network3.png';
import sunicon from '../../../components/assets/sun.png';
import solarprofit from '../../../components/assets/solar-profit.png';
import sus from '../../../components/assets/sustain.png';
import plugs from '../../../components/assets/plug.png';

import './service.css';



import solarpanels from '../../../components/assets/1234.png'

const Servicepage = () => {
  const solarWorkInfo = [
    'פאנלים סולאריים סופגים את אור השמש וממירים אותו לחשמל זרם ישר (DC).',
    'המהפך ממיר חשמל DC לחשמל זרם חילופין (AC), המשמש בבתים ובבניינים',
    'כל עודפי חשמל המיוצרים על ידי הפאנלים הסולאריים נשלחים בחזרה לרשת, ומרוויחים זיכויים לבעל הבית',
    'פאנלים סולאריים עובדים בצורה הטובה ביותר כאשר הם מותקנים על גג הפונה דרומה עם מעט או ללא צל',
    'ניתן לכוונן את זווית הפאנלים כדי למקסם את ייצור האנרגיה בהתאם לעונה',
    'אנרגיה סולארית היא מקור אנרגיה מתחדש שמפחית את התלות בדלקים מאובנים ומפחית את פליטת הפחמן'
  ];

  return (
  <div className='services-container-heb'>
     <div className='intro-text'>
        <h1 className='intro-title'>כיצד פועלים פאנלים סולאריים</h1>
        <p className='intro-description'>
        אנלים סולאריים רותמים את כוחה של השמש לייצור חשמל נקי ומתחדש. על ידי הבנת התהליך של אופן הפעולה של פאנלים סולאריים, אתה יכול להעריך את היתרונות של אנרגיה סולארית ואת ההשפעה החיובית שלה על הסביבה.
        </p>
      </div>
  <div className='solarwork-info-heb'>
    <div className='solarwork-left-heb'>
      <div className='solarwork-panel-heb'>

        <img className='solarwork-panel-img-heb' src={sunicon} alt='solar panel' loading='lazy'/>
        <div className='solarwork-panel-text-heb'>
          <p className='solarwork-panel-title-heb'>פנלים סולאריים</p>
          <p className='solarwork-panel-info-heb'>{solarWorkInfo[0]}</p>
        </div>
      </div>
      
      <div className='solarwork-inverter-heb'>
        <img className='solarwork-inverter-img-heb' src={invertericon} alt='inverter' loading='lazy'/>
        <div className='solarwork-inverter-text-heb'>
          <p className='solarwork-inverter-title-heb'>ממיר מתח</p>
          <p className='solarwork-inverter-info-heb'>{solarWorkInfo[2]}</p>
        </div>
      </div>

      <div className='solarwork-home-heb'>
        <img className='solarwork-home-img-heb' src={solarhouseicon} alt='home' loading='lazy'/>
        <div className='solarwork-home-text-heb'>
          <p className='solarwork-home-title-heb'>בית</p>
          <p className='solarwork-home-info-heb'>{solarWorkInfo[4]}</p>
        </div>
      </div>
    </div>

    <img className='solarwork-image-heb' src={solarwork} alt='solar panel' loading='lazy'/>
    

    <div className='solarwork-right-heb'>

      <div className='solarwork-meter-heb'>
        <img className='solarwork-meter-img-heb' src={plugs} alt='meter' loading='lazy'/> 
        <div className='solarwork-meter-text-heb'>
          <p className='solarwork-meter-title-heb'>מדיד</p>
          <p className='solarwork-meter-info-heb'>{solarWorkInfo[1]}</p>
        </div>
      </div>

      <div className='solarwork-grid-heb'>
        <img className='solarwork-grid-img-heb' src={energynet} alt='grid' loading='lazy'/>
        <div className='solarwork-grid-text-heb'>
          <p className='solarwork-grid-title-heb'>רשת</p>
          <p className='solarwork-grid-info-heb'>{solarWorkInfo[3]}</p>
        </div>
      </div>

      <div className='solarwork-garden-heb'>
        <img className='solarwork-garden-img-heb' src={sus} alt='garden' loading='lazy'/>
        <div className='solarwork-garden-text-heb'>
          <p className='solarwork-garden-title-heb'>גן</p>
          <p className='solarwork-garden-info-heb'>{solarWorkInfo[5]}</p>
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default Servicepage;
import React, { Component } from 'react';
import image1 from '../../../components/assets/checklist.png'
import image2 from '../../../components/assets/calculator.png'
import image3 from '../../../components/assets/schedule.png'
import image4 from '../../../components/assets/eco-house.png'

import './Calc.css'

const Caclpage = () => {
    return ( 
        <div className='calc-container'>
        <p className="calc-heading"> מחשבון עלויות ושטח
</p>
        <div className='calc-main'>
          <div className="step-item">
            <p>שלב 1
</p>
            <div className="step-image-container">
              <img className="step-image" src={image1} alt="Submit the details of your renovation" />
            </div>
            <h3>השאירו פרטי תקשורת באתר</h3>
          </div>
          <div className="step-line"></div>
          <div className="step-item">
            <p>שלב 2
</p>
            <div className="step-image-container">
              <img className="step-image" src={image2} alt="Get cost estimate" />
            </div>
            <h3>עלות</h3>
          </div>
          <div className="step-line"></div>
          <div className="step-item">
            <p>שלב 3
</p>
            <div className="step-image-container">
              <img className="step-image" src={image3} alt="Book preferred service time" />
            </div>
            <h3>הזמינו זמן שירות מועדף
</h3>
          </div>
          <div className="step-line"></div>
          <div className="step-item">
            <p>שלב 4
</p>
            <div className="step-image-container">
              <img className="step-image" src={image4} alt="We will contact you to get info" />
            </div>
            <h3>ניצור איתך קשר כדי לקבל מידע</h3>
          </div>
        </div>
        <p className="calc-subheading">לחישוב ממוצע החזרים ושטח</p>
        <a href="/calculate" className="calc-button">חשב</a>

      </div>
      
     );
}
 
export default Caclpage;
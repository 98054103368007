import React from "react";
import { slide as Menu } from "react-burger-menu";
import './sidebar.css';
export default (props) => {
  return (
    // Pass on our props
    <Menu right {...props}>
      <a className="menu-item" href="#home">
        Home
      </a>

      <a className="menu-item" href="#about">
        About us
      </a>

      <a className="menu-item" href="#services">
        SERVICES
      </a>
      <a className="menu-item" href="#contact">
      CONTACT
      </a>
      <a className="menu-item" href="/calculate">
      Cost Calcuator
      </a>
    </Menu>
  );
};

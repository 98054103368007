import React, { useRef, useEffect } from 'react';
import carsolar from '../../../components/assets/download.jpg';
import './xp.css';
import { Parallax } from 'react-parallax';

const ExperincepageHEB = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const resizeHandler = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        const windowHeight = window.innerHeight;
        const parallaxElement = containerRef.current.querySelector('.parallax-element');
        
        if (parallaxElement) {
          const parallaxHeight = Math.max(containerHeight, windowHeight);
          parallaxElement.style.height = `${parallaxHeight}px`;
        }
      }
    };

    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div id="XP-container" className="exp-container" ref={containerRef}>
      <Parallax strength={100} bgImage={carsolar}>
        <div className="XP-content parallax-element">
          <h2 className="XP-heading">קצת עלינו</h2>
          <p className="XP-text">
            אנו מחויבים לספק <span className="XP-text-color">שהתקנות פאנלים סולאריים באיכות מעולה. </span>
          </p>
        </div>
      </Parallax>
    </div>
  );
};

export default ExperincepageHEB;
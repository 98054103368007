import React, { useState, useEffect } from 'react';
import './main.css';
import TextField from '@mui/material/TextField';

import logo from '../../../components/assets/logogo.png';
import gifFile from '../../../components/assets/ezgif.com-video-to-gif.gif';
import videoFile from '../../../components/assets/film_homepage_3256dsfds.mp4';

const Mainpage = () => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    // listen for page refresh event
    window.addEventListener('beforeunload', () => {
      // reset the state
      setLoaded(false);
    });

    // cleanup function to remove event listener
    return () => {
      window.removeEventListener('beforeunload', () => {
        setLoaded(false);
      });
    };
  }, []);

  return (
    <div className='Mainpage-heb'>
      <div className='outer-wrapper'>
        <div className='gif-wrapper-heb'>
        <img
          className="home-main-video-heb"
          src={gifFile}
          alt="solar panel"
          
          
          muted
          loop
        
        />
        </div>

        <div className='lmao-div'>
          {/* <img className='logo-main' src={logo}/> */}
          <p className='lmao'>בדרך אל הגג הבא</p>
          <button className='main-btn'>גלו עוד</button>
        </div>

        <div className='contact-main'>
          <p className='contact-title'>מלאו פרטים למטה לקבלת הצעה למערכת סולארית</p>
          <form>
            <TextField type='text' id='name' name='name' label='שם' variant='filled' required sx={{ bgcolor: 'white' }} />
            <TextField type='email' id='email' name='email' label='מייל' variant='filled' required sx={{ bgcolor: 'white' }} />
            <TextField id='phone' name='phone' label='טלפון' required variant='filled' sx={{ bgcolor: 'white' }} />
            <button className='contact-submit' type='submit'>Get started</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Mainpage;

import React, { Component } from 'react';
import navlogo from '../../../components/assets/logogo2.png'
import globe from '../../../components/assets/globe.png'
import SideBar from "../app/Sidebar";
import phonelogo from '../../../components/assets/phone-call.png'

function toggleMenu() {
    const menu = document.querySelector('.nav_menu');
  
    menu.classList.toggle('active');
  }
const Outernavbarheb = () => {
    return ( 
        <div className='outernav-main'>
            <nav className="navbar special " style={{ backgroundColor: 'white' }}>
    <div className="navbar-container">
      <div className="brand-container">
        <img src={navlogo} className="navbar-brand" href="/" />
      </div>
      <div className="nav-menu-container">
        <ul className="nav_menu">
        <li data-nav="home"><a className="nav_link" href="/"  style={{ color: 'black' }}>מסך הבית</a></li>
          <li data-nav="about"><a className="nav_link" href="#about" style={{ color: 'black' }}>אנרגיה סולארית</a></li>
          <li data-nav="services"><a className="nav_link" href="#services" style={{ color: 'black' }}>פתרונות סולאריים</a></li>
          <li data-nav="calc"><a className="nav_link" href="#calc" style={{ color: 'black' }}>הערכת עלות ושטח</a></li>
          <li data-nav="contact"><a className="nav_link" href="#contact" style={{ color: 'black' }}>צרו קשר</a></li>
        </ul>
      </div>
      <div className="phone-container">
        <img src={phonelogo} className="phone_logo" />
        <a className="phone_link" href="tel:+971523425123" style={{ color: 'black' }} >דברו איתנו</a>
        <div className="dropdown">
          <img src={globe} className="globe-language" onClick={toggleMenu} />
          <div className="dropdown-content">
          <a href="/">עברית</a>
            <a href="/en">English</a>
          </div>
        </div>
      </div>
      <div id="sidebarid">
        <SideBar pageWrapId={"page-wrap"} outerContainerId={"sidebarid"} />
        <div id="page-wrap"></div>
      </div>
    </div>
  </nav>

        



        </div>
     );
}
 
export default Outernavbarheb;
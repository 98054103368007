import React from 'react';
import './Tos.css'; // Import the CSS file for styling

const TosPage = () => {
    return (
      <div className="tos-container">
        <h1 className="tos-title">Terms of Service</h1>
        <p className="tos-text">
          Effective Date: [Insert Effective Date]
        </p>
  
        <h2>1. Website Use</h2>
        <p>
          a. You may use the Website for informational purposes and to access the services provided by our company.
          <br />
          b. You agree not to use the Website for any unlawful or unauthorized purposes.
        </p>
  
        <h2>2. Intellectual Property</h2>
        <p>
          a. All content, materials, and features available on the Website, including but not limited to text, graphics, logos, images, audio clips, video clips, software, and data compilations, are the property of our company or its licensors and are protected by applicable intellectual property laws.
          <br />
          b. You may not reproduce, distribute, modify, transmit, perform, display, or create derivative works of any content on the Website without our prior written consent.
        </p>
  
        <h2>3. User Conduct</h2>
        <p>
          a. By using the Website, you agree to comply with all applicable laws and regulations.
          <br />
          b. You agree not to engage in any activity that may disrupt or interfere with the operation of the Website or the services provided.
          <br />
          c. You are solely responsible for any content you submit or post on the Website, and you agree not to upload or transmit any content that is harmful, offensive, or violates any third-party rights.
        </p>
  
        <h2>4. Privacy</h2>
        <p>
          a. Our company respects your privacy and handles your personal information in accordance with our Privacy Policy.
          <br />
          b. By using the Website, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.
        </p>
  
        <h2>5. Third-Party Websites and Services</h2>
        <p>
        a. The Website may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any third-party websites or services.
        </p>
  
        <h2>6. Disclaimer of Warranty</h2>
        <p>
        a. The Website and its contents are provided on an "as is" and "as available" basis. We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
b. We do not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the content on the Website or otherwise relating to such content or on any websites linked to the Website.
        </p>

        <h2>7. Limitation of Liability</h2>
        <p>
        a. In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Website; (ii) any conduct or content of any third party on the Website; (iii) any content obtained from the Website; or (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.
        </p>

        <h2>8. Modifications to the TOS</h2>
        <p>
        a. We reserve the right to modify or replace these TOS at any time without prior notice. The updated version of the TOS will be posted on the Website. Your continued use of the Website after any such changes constitutes your acceptance of the new TOS.
        </p>
  
      </div>
    );
  };
  
  export default TosPage;
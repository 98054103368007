import React from 'react';
import image from '../../../components/assets/solar-work.webp';
import solaricon from '../../../components/assets/solar-energy.png';
import solarprofit from '../../../components/assets/solar-profit.png';
import greenhouse from '../../../components/assets/green-home.png';
import './Bout.css';

const AboutPage = () => {
  return (
    <div className='bout-div'>
 
      <p className='solarity-us'>At Icargo, our team of experts is dedicated to providing top-quality solutions to all your needs. With years of experience and ongoing training, we are committed to staying ahead of the curve and finding innovative ways to help you achieve your goals. Count on us for 24/7 support and unmatched results.</p>

      <div className='bout-container'>
   

        <div className='bout-solar-div'>
          <div className='bout-flex-container'>
            <img className='bout-solar-icon' src={solaricon} alt='solar icon' loading='lazy'/>
            <p id='about-solar-title'>About solar</p>
          </div>
          <p id='about-solar-text'>Solar energy is a clean and renewable source of power that has the potential to revolutionize the way we generate electricity. Unlike traditional fossil fuels, solar energy is abundant and readily available, making it a sustainable alternative that can reduce our carbon footprint and combat climate change. </p>
        </div>

        <div className='profit-from-solar'>
          <div className='bout-flex-container'>
            <img className='bout-solar-profit' src={solarprofit} alt='solar profit' loading='lazy'/>
            <p id='profit-solar-title'>Solar for Profit</p>
          </div>
          <p id='profit-solar-text'>Investing in solar energy is not only good for the planet, it's also good for your wallet. By generating your own electricity, you can reduce your reliance on expensive utility companies and take control of your energy costs. At Icargo, we offer a range of financing options to help you make the switch to solar without breaking the bank. </p>
        </div>

        <div className='future-of-solar'>
          <div className='bout-flex-container'>
            <img className='future-profit-icon' src={greenhouse} alt='solar profit' loading='lazy'/>
            <p id='future-of-title'>The Future is Solar-Powered</p>
          </div>
          <p id='future-of-text'>Advancements in solar technology have made it easier than ever to harness the power of the sun for everyday use. From rooftop solar panels to community solar gardens, there are a variety of options available to help you make the switch to clean, renewable energy. </p>
        </div>


    </div>
      <div className='bout-image-border-div'>
        <img className='bout-worker-image' src={image} alt='solar worker' loading='lazy'/>
      </div>
    </div>
  );
};

export default AboutPage;
import React, { Component, useEffect, } from "react";
import { slide as Menu } from 'react-burger-menu';

import SideBar from "./Sidebar";

import './Application.css';
import Mainpage from '../Main/Main';
import AboutPage from "../bout/Bout";
import Servicepage from "../service/Service";
import Footer2 from "../footer2/Footer2";
import Service2page from "../service2/Service2";
import Caclpage from "../Calc/Calc";
import Experincepage from "../XP/Xp";
import Footerpage from "../foot/Foot";
import Phone from "../../phone/Phone";
import navlogo from '../../../components/assets/logogo3.png'
import navlogo2 from '../../../components/assets/logogo2.png'
import phonelogo from '../../../components/assets/phone-call.png'
import globe from '../../../components/assets/globe.png'
import globebur from '../../../components/assets/more.png'
import ScrollingPhone from "../../phone/Phone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
function Applicationpage() {




  window.addEventListener('load', function () {
    const sections = document.querySelectorAll('section');
    const nav = document.querySelector('nav');
    const navList = document.querySelectorAll('.nav_menu li');

    const updateLogo = () => {
      const logo = document.querySelector('.navbar-brand');
      if (nav.classList.contains('active')) {
        logo.src = navlogo2;
      } else {
        logo.src = navlogo;
      }
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        if (e.isIntersecting) {
          console.log(e.target.id);

          if (e.target.id !== 'home') {
            nav.classList.add('active');
          } else {
            nav.classList.remove('active');
          }

          navList.forEach((link) => {
            link.classList.remove('active');
            if (e.target.id === link.dataset.nav) {
              link.classList.add('active');
            }
          });
        }
      });

      updateLogo(); // Update the logo when the intersection changes
    }, { threshold: 0.6 });

    sections.forEach((section) => {
      observer.observe(section);
    });

    sections.forEach((section) => {
      if (
        section.getBoundingClientRect().top < window.innerHeight * 0.6 &&
        section.getBoundingClientRect().bottom > window.innerHeight * 0.6
      ) {
        observer.observe(section);
        if (section.id !== 'home') {
          nav.classList.add('active');
        } else {
          nav.classList.remove('active');
        }
      }
    });
  });

  function toggleMenu() {
    const menu = document.querySelector('.nav_menu');
    menu.classList.toggle('active');
  }

  return (
    <div className="Application1">
      <nav className="navbar">
        <div className="navbar-container">
          <div className="brand-container">
            <img
              src={navlogo}
              className="navbar-brand"
              href="#"
              alt="Logo"
            />
      </div>
      <div className="nav-menu-container">
        <ul className="nav_menu">
        <li data-nav="home"><a className="nav_link" href="/en#home">Home</a></li>
          <li data-nav="about"><a className="nav_link" href="#about">Solar Energy</a></li>
          <li data-nav="services"><a className="nav_link" href="#services">Solar Solutions</a></li>
          <li data-nav="calc"><a className="nav_link" href="#calc">Cost Estimate</a></li>
          <li data-nav="footers"><a className="nav_link" href="#footers">Contact</a></li>
        </ul>
      </div>

      <div className="phone-container">
        <img src={phonelogo} className="phone_logo" />
        <a className="phone_link" href="tel:+972547777391">CALL</a>
        <div className="dropdown">
          <img src={globe} className="globe-language" onClick={toggleMenu} />
          <div className="dropdown-content">
            <a href="/">עברית</a>
            <a href="/en">English</a>
          </div>
        </div>
      </div>
      {/* <div id="sidebarid">
        <SideBar pageWrapId={"page-wrap"} outerContainerId={"sidebarid"} />
        <div id="page-wrap"></div>
      </div> */}
    </div>
  </nav>

      <div>
    
        <section className='home-section' id="home"><Mainpage/></section>
        <section className='about-section'id="about" ><AboutPage/></section>
        <section className='services-section' id="services"><Servicepage/></section>
        <section className='contact-section' id="services"><Service2page/></section>
        <section className='calc-section' id="calc"><Caclpage/></section>
        <section className='xp-section' id="exp"><Experincepage/></section>
        <section className='foot-section' id="footers"><Footerpage/></section>

      </div>
    <Footer2/>
     
    </div>
  );
}

export default Applicationpage;

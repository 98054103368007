import React, { useState } from 'react';


import './Footer2.css'
const Footer2 = () => {
    const currentYear = new Date().getFullYear();
    return ( 

        <footer className="footer">
        <div className="footer-container">
          <p>&copy; {currentYear} Icargo. All rights reserved.</p>
          <ul className="footer-menu">
            <li><a href="/tos">Terms and Conditions</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/faq">FAQ</a></li>
          </ul>
        </div>
      </footer>
     );
}
 
export default Footer2;
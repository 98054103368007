import React, { Component } from 'react';
import image1 from '../../../components/assets/checklist.png'
import image2 from '../../../components/assets/calculator.png'
import image3 from '../../../components/assets/schedule.png'
import image4 from '../../../components/assets/eco-house.png'

import './Calc.css'

const Caclpage = () => {
    return ( 
        <div className='calc-container'>
        <p className="calc-heading">Cost Calculator</p>
        <div className='calc-main'>
          <div className="step-item">
            <p>Step 1</p>
            <div className="step-image-container">
              <img className="step-image" src={image1} alt="Submit the details of your renovation" />
            </div>
            <h3>Submit the details of your renovation</h3>
          </div>
          <div className="step-line"></div>
          <div className="step-item">
            <p>Step 2</p>
            <div className="step-image-container">
              <img className="step-image" src={image2} alt="Get cost estimate" />
            </div>
            <h3>Get cost estimate</h3>
          </div>
          <div className="step-line"></div>
          <div className="step-item">
            <p>Step 3</p>
            <div className="step-image-container">
              <img className="step-image" src={image3} alt="Book preferred service time" />
            </div>
            <h3>Book preferred service time</h3>
          </div>
          <div className="step-line"></div>
          <div className="step-item">
            <p>Step 4</p>
            <div className="step-image-container">
              <img className="step-image" src={image4} alt="We will contact you to get info" />
            </div>
            <h3>We will contact you to get info</h3>
          </div>
        </div>
        <p className="calc-subheading">Calculate your cost and ROI</p>
        <a href="/en/calculate" className="calc-button">Calculate</a>

      </div>
      
     );
}
 
export default Caclpage;
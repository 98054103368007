import React, { Component } from 'react';

import './service2.css'
import img1 from '../../../components/assets/solar-panels.jpg';
import img2 from '../../../components/assets/ind-solar.jpg';
import img3 from '../../../components/assets/solar-installers.webp';
import img4 from '../../../components/assets/solar-consulting.jpg';


import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

const Service2page = () => {
    return ( 
        <div className='service2-main'>
         <div className='service2-container'>
    <div className="section-with-cross-overlay">
    <div className='service2-text'>
  {/* Content of the section goes here */}
      <h2>Welcome to our Solar Services!</h2>
      <p>We specialize in providing high-quality solar power solutions for both residential and commercial properties. Our team of experts can design and install customized solar systems to meet your energy needs and budget. We use only the best equipment and materials, including imports from trusted suppliers, to ensure the best performance and reliability. With solar power, you can not only reduce your energy bills but also profit from excess energy production through net metering. Let us guide you through the process and help you take advantage of the many benefits of solar energy.</p>
</div>
    </div>
    <div className='service2-services-icons'>
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="250"
                        image={img1}
                        loading='lazy'
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Residential Solar Services
                        </Typography>
                        <Typography variant="body2" color="black">
                        We offer complete solar power solutions for your home, including installation, maintenance, and repair services. Our team of experts will work with you to design a customized solar system that fits your energy needs and budget.
                        </Typography>
                    </CardContent>
                </Card>

                   <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="250"
                        image={img2}
                        loading='lazy'
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Commercial Solar Services
                        </Typography>
                        <Typography variant="body2" color="black">
                        We provide comprehensive solar power services for businesses of all sizes. Our team has extensive experience installing and maintaining solar systems for commercial properties, and we can help you reduce your energy costs and increase your sustainability.
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="250"
                        image={img3}
                        loading='lazy'
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Solar Panel Installation
                        </Typography>
                        <Typography variant="body2" color="black">
                        We specialize in solar panel installation for homes and businesses. Our team will work with you to design a system that meets your energy needs and maximizes your energy savings. We use high-quality solar panels and equipment to ensure the best performance and reliability.
                        </Typography>
                    </CardContent>
                </Card>
                    
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="250"
                        image={img4}
                        loading='lazy'
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        Solar Energy Consulting
                        </Typography>
                        <Typography variant="body2" color="black">
                        Our team of solar energy experts can provide you with comprehensive consulting services to help you understand the benefits of solar power and make informed decisions about your energy needs. From site assessment to financial analysis, we can guide you through every step of the process.
                        </Typography>
                    </CardContent>
                </Card>
                </div>
            </div>
        </div>
     );
}
 
export default Service2page;
import React, { useState , useEffect } from 'react';
import Outernavbar from '../outnav/Outnav';
import './calculator.css';
import Footerpage from '../foot/Foot';
import Footer2 from '../footer2/Footer2';
import mainsolar from '../../../components/assets/ind-solar.jpg';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';


import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
// import SolarCalculator from '../earth/Earthsolar';

const Solarslider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const CalgulatorPage = () => {
  const [monthlyEnergyUse, setMonthlyEnergyUse] = useState(80);
  const [costOfElectricity, setCostOfElectricity] = useState(0.15);
  const [offset, setOffset] = useState(0);
  const [estimatedROI, setEstimatedROI] = useState(0);
  const [systemSize, setSystemSize] = useState(10);
  const optimalGenerationPerKW = 1650;

  function valuetext(value: number) {
    return `${value}°C`;
  }

  useEffect(() => {
    calculateROI();
  }, [monthlyEnergyUse, costOfElectricity, offset,systemSize ]);

  function calculateROI() {
    const totalCostOfSolarPanels = 15000;
    const estimatedYearlyGeneratedKWh = systemSize * optimalGenerationPerKW;
    const totalYearlyReturn = estimatedYearlyGeneratedKWh * costOfElectricity * systemSize;
    const total25Years = totalYearlyReturn * 25;
    const paybackPeriod = totalCostOfSolarPanels / totalYearlyReturn;
    setEstimatedROI(paybackPeriod.toFixed(2));
  }

  

  return (
    <div className='calc-second'>
      <Outernavbar />
      <div className="page-content">
        <div className="image-container">
          <img src={mainsolar} alt="Main Solar" className="main-solar-image" loading='lazy'/>
        </div>



        
        <div className="calc-text">

        <p id='calc-title'>Caulculate Savings</p>


        <p id='calc-intro-text'>Objectively integrate emerging core competencies before process-centric communities.</p>

        </div>

        <div className="ROI-calc">
      <Grid container spacing={3}>
        <Grid item>
          <Box sx={{ width: 400 }}>
            <Typography gutterBottom>Monthly Energy Use</Typography>
            <Solarslider
              aria-label="Always visible"
              defaultValue={monthlyEnergyUse}
              getAriaValueText={valuetext}
              step={1}
              valueLabelDisplay="on"
              min={100}
              max={1000}
              onChange={(event, value) => setMonthlyEnergyUse(value)}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item>
          <Box sx={{ width: 400 }}>
            <Typography gutterBottom>Cost of Electricity per kWh</Typography>
            <Solarslider
              aria-label="pretto slider"
              defaultValue={costOfElectricity}
              getAriaValueText={valuetext}
              step={0.01}
              valueLabelDisplay="auto"
              min={0.10}
              max={0.50}
              onChange={(event, value) => setCostOfElectricity(value)}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item>
          <Box sx={{ width: 400 }}>
            <Typography gutterBottom>Offset</Typography>
            <Solarslider
              aria-label="Always visible"
              defaultValue={offset}
              getAriaValueText={valuetext}
              step={1}
              valueLabelDisplay="on"
              min={0}
              max={1000}
              onChange={(event, value) => setOffset(value)}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item>
          <Box sx={{ width: 400 }}>
            <Typography gutterBottom>System Size (in kW)</Typography>
            <Solarslider
              aria-label="System Size"
              defaultValue={systemSize}
              getAriaValueText={valuetext}
              step={0.1}
              valueLabelDisplay="auto"
              min={1}
              max={35}
              onChange={(event, value) => setSystemSize(value)}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Display the ROI */}
      <div className="roi-display" style={{ marginTop: "20px", textAlign: "center" }}>
        <Typography variant="h6" gutterBottom style={{ fontSize: "18px", fontWeight: "bold", color: "#555" }}>
          Estimated ROI: {estimatedROI} years
        </Typography>
      </div>
    </div>


        <div style={{ width: '100%', marginTop: 10 }}>
          {/* call element below */}
          <p className='iframe-text'>Caclulate Area of your roof</p>
          {/* <SolarCalculator/> */}
          <iframe
            height="500"
            style={{ width: "100%" }}
            scrolling="no"
            title="Solar Calculator"
            src="https://cargo-solar.web.app"
            frameborder="no"
            loading="lazy"
            allowtransparency="true"
            allowfullscreen="true"
          >
          </iframe>
        </div>
      </div>
        <Footer2/>
    </div>
  );
};

export default CalgulatorPage;
import React from 'react';
import solarwork from '../../../components/assets/inner_solar.png';
import invertericon from '../../../components/assets/converter.png';
import solarhouseicon from '../../../components/assets/eco-house123.png';
import energynet from '../../../components/assets/network3.png';
import sunicon from '../../../components/assets/sun.png';
import solarprofit from '../../../components/assets/solar-profit.png';
import sus from '../../../components/assets/sustain.png';
import plugs from '../../../components/assets/plug.png';

import './service.css';



import solarpanels from '../../../components/assets/1234.png'

const Servicepage = () => {
  const solarWorkInfo = [
    'Solar panels absorb sunlight and convert it into direct current (DC) electricity',
    'The inverter converts DC electricity into alternating current (AC) electricity, which is used in homes and buildings',
    'Any excess electricity produced by the solar panels is sent back to the grid, earning credits for the homeowner',
    'Solar panels work best when they are installed on a south-facing roof with little to no shade',
    'The angle of the panels can be adjusted to maximize energy production based on the season',
    'Solar power is a renewable energy source that reduces reliance on fossil fuels and lowers carbon emissions'
  ];

  return (
  <div className='services-container'>
     <div className='intro-text'>
        <h1 className='intro-title'>How Solar Panels Work</h1>
        <p className='intro-description'>
          Solar panels harness the power of the sun to generate clean and renewable electricity. By understanding the process of how solar panels work, you can appreciate the benefits of solar energy and its positive impact on the environment.
        </p>
      </div>
  <div className='solarwork-info'>
    <div className='solarwork-left'>
      <div className='solarwork-panel'>
        <img className='solarwork-panel-img' src={sunicon} alt='solar panel' loading='lazy'/>
        <div className='solarwork-panel-text'>
          <p className='solarwork-panel-title'>SOLAR PANELS</p>
          <p className='solarwork-panel-info'>{solarWorkInfo[0]}</p>
        </div>
      </div>
      <div className='solarwork-inverter'>
        <img className='solarwork-inverter-img' src={invertericon} alt='inverter' loading='lazy'/>
        <div className='solarwork-inverter-text'>
          <p className='solarwork-inverter-title'>INVERTER</p>
          <p className='solarwork-inverter-info'>{solarWorkInfo[2]}</p>
        </div>
      </div>
      <div className='solarwork-home'>
        <img className='solarwork-home-img' src={solarhouseicon} alt='home' loading='lazy'/>
        <div className='solarwork-home-text'>
          <p className='solarwork-home-title'>HOME</p>
          <p className='solarwork-home-info'>{solarWorkInfo[4]}</p>
        </div>
      </div>
    </div>
    <img className='solarwork-image' src={solarwork} alt='solar panel' />
    <div className='solarwork-right'>
      <div className='solarwork-meter'>
        <img className='solarwork-meter-img' src={plugs} alt='meter' loading='lazy'/>
        <div className='solarwork-meter-text'>
          <p className='solarwork-meter-title'>METER</p>
          <p className='solarwork-meter-info'>{solarWorkInfo[1]}</p>
        </div>
      </div>
      <div className='solarwork-grid'>
        <img className='solarwork-grid-img' src={energynet} alt='grid' loading='lazy'/>
        <div className='solarwork-grid-text'>
          <p className='solarwork-grid-title'>NETWORK GRID</p>
          <p className='solarwork-grid-info'>{solarWorkInfo[3]}</p>
        </div>
      </div>
      <div className='solarwork-garden'>
        <img className='solarwork-garden-img' src={sus} alt='garden' loading='lazy' />
        <div className='solarwork-garden-text'>
          <p className='solarwork-garden-title'>GARDEN</p>
          <p className='solarwork-garden-info'>{solarWorkInfo[5]}</p>
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default Servicepage;
import React, { Component } from 'react';
import solarinstaller from '../../../components/assets/download2.jpg'
import clockhours from '../../../components/assets/clock.png'
import locationicon from '../../../components/assets/location.png'
import customercall from '../../../components/assets/customer-service.png'
import suplol from '../../../components/assets/whatsapp.png'


import './foot.css'
const Footerpage = () => {
    return (
      <div className="footer-container">
        <div className="rectangle">
          <div className="image-section">
            <img src={solarinstaller} alt="solar installer" />
          </div>
          <div className="text-section">
            <h2>ICARGO</h2>
            <p>At Icargo, our team of experts is dedicated to providing top-quality solutions to all your needs. With years of experience and ongoing training, we are committed to staying ahead of the curve and finding innovative ways to help you achieve your goals. Count on us for 24/7 support and unmatched results.</p>
          </div>
        </div>
        
        <div className='office-info-grid'>
  <div className='office-info-grid-item'>
    <div className='office-info-grid-item-content'>
      <img src={clockhours}/>
      <div className='office-info-grid-item-text'>
        <h4>WORKING HOURS</h4>
        <p>Mon - Thu 8.00 - 18.00</p>
      </div>
    </div>
  </div>
  <div className='office-info-grid-item'>
    <div className='office-info-grid-item-content'>
      <img src={locationicon}/>
      <div className='office-info-grid-item-text'>
        <h4>LOCATION</h4>
        <p>Ha'tzmaut 102, Haifa</p>
      </div>
    </div>
  </div>
  <div className='office-info-grid-item'>
    <div className='office-info-grid-item-content'>
      <img src={customercall}/>
      <div className='office-info-grid-item-text'>
        <h4>CALL CENTER</h4>
        <p>054-7777391</p>
        <p>Give us a free call 24/7</p>
      </div>
    </div>
  </div>
  <div className='office-info-grid-item'>
    <div className='office-info-grid-item-content'>
      <img src={suplol}/>
      <div className='office-info-grid-item-text'>
        <h4>WRITE US</h4>
        <p>office@bold-themes.com</p>
        <p>anan@icargo.ltd</p>
      </div>
    </div>
  </div>
</div>
</div>
    );
  }
export default Footerpage;